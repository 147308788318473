﻿import * as React from 'react';
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const About = () => {

    

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'about_title' })}</title>
                <meta name="description" content={Text({ tid: 'about_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'about_title' })} />
                <meta property="og:description" content={Text({ tid: 'about_description' })} />
            </Helmet>
            <div id="container_contant">
                <div className="about-us-box">
                    <div className="about-us-text about-us-page">
                        <h2><Text tid='about_big_h2' /></h2>
                        <p><Text tid='about_big_p1' /></p>
                        <p><Text tid='about_big_p2' /></p>
                        <p><Text tid='about_big_p3' /></p>
                        <p><Text tid='about_big_p4' /></p>
                        <p className="text-italic"><Text tid='about_big_p5' /></p>
                        <p><Text tid='about_big_p6' /></p>
                    </div>
                    <div className="why-us-text">
                        
                        <div className="why-us-box">
                            <h2><Text tid='about_small_h2' /></h2>
                            <ul>
                                <li><Text tid='about_small_l1' /></li>
                                <li><Text tid='about_small_l2' /></li>
                                <li><Text tid='about_small_l3' /></li>
                                <li><Text tid='about_small_l4' /></li>
                                <li><Text tid='about_small_l5' /></li>
                                <li><Text tid='about_small_l6' /></li>
                            </ul>
                            <h5 style={{ fontStyle: 'italic', fontSize: '32px' }}><Text tid='about_small_h5' /></h5>
                        </div>
                        <img src="../../img/about_us_bg.jpg" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default About