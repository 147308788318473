﻿import * as React from 'react';
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const Privacy = () => {



    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'pivacy_title' })}</title>
                <meta name="description" content={Text({ tid: 'pivacy_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'pivacy_title' })} />
                <meta property="og:description" content={Text({ tid: 'pivacy_description' })} />
            </Helmet>
            <div className="service-box">
                <div className="service-text" style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: Text({ tid: 'pivacy' }) }}> 
                </div>
            </div>
        </React.Fragment>
    );
}

export default Privacy