﻿import * as React from 'react';

const AdminPanel = (props: any) => {

    const [login, setLogin] = React.useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();

        const log = e.target[0].value;
        const pass = e.target[1].value;

        if (log.trim() != '' && pass.trim() != '') {
            fetch(`/api/adminpanel`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Login: log,
                    Password: pass
                })
            })
            .then(response => response.json())
            .then(data => {
                setLogin(data);
            })
            .catch(err => console.log(err));
        }

    }

    return (
        <React.Fragment>
            {login ?
                <div>
                
                </div>
                : 
                <div className='admin-panel'>
                    <form method="post" onSubmit={onSubmit}>
                        <div className="form-group">
                            <label style={{ fontSize: '18px' }} htmlFor="exampleInputLogin">Логин</label>
                            <input type="text" className="form-control" id="inputLogin" name="login" required />
                        </div>
                        <div className="form-group">
                            <label style={{ fontSize: '18px' }} htmlFor="exampleInputPassword1">Пароль</label>
                            <input type="password" className="form-control" id="inputPassword"
                                name="password" required />
                        </div>
                        <button type="submit" style={{ marginTop: '7px' }} className="btn btn-primary">Войти</button>
                    </form>
                </div>    
            }
        </React.Fragment>
    );
}

export default AdminPanel