﻿import { useEffect } from 'react';

const useScript = (url: any) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        // @ts-ignore
        document.querySelector('.add-script').appendChild(script);
    }, [url]);
};

export default useScript;