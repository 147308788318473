import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Slider } from '../Slider';
import { LanguageContext, Text } from './../Language'

const ApplicationOnLicence = () => {

    return (
        <React.Fragment>

            <div className="app_on_licence">
                <p><Text tid='app_on_licence' /></p>
            </div>
                
        </React.Fragment>
    );
}

export default ApplicationOnLicence