import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './custom.css'
import Main from './components/pages/Main';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Services from './components/pages/Services';
import Privacy from './components/pages/Privacy';
//import ApplicationsShop from './components/pages/ApplicationsShop';
//import Application from './components/pages/Application';
import ScrollToTop from './components/ScrollToTop';
import Vacancy from './components/pages/Vacancy';
import AdminPanel from './components/pages/AdminPanel';
import NotFound from './components/pages/NotFound';
import ApplicationOnLicence from './components/pages/ApplicationOnLicence';

export default () => (
    <Layout>
        <ScrollToTop />
        <Switch>
            <Route exact path='/' component={Main} />
            <Route path='/about' component={About} />
            <Route path='/contacts' component={Contact} />
            <Route path='/services' component={Services} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/shop/:id' component={ApplicationOnLicence} />
            <Route path='/shop' component={ApplicationOnLicence} />
            <Route path='/vacancy' component={Vacancy} />
            <Route path='/admin' component={AdminPanel} />
            <Route path="" component={NotFound} />
        </Switch>
    </Layout>
);
