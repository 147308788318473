﻿import * as React from 'react';
import { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from '../languages';

export const LanguageContext = createContext({
    userLanguage: 'ru',
    dictionary: dictionaryList.ru
});

export function LanguageProvider({ children }:any) {
    const defaultLanguage = window.localStorage.getItem('sprintm-lang');
    const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'ru');

    const provider: any = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected: any) => {
            const newLanguage = languageOptions[selected] ? selected : 'ru'
            setUserLanguage(newLanguage);
            window.localStorage.setItem('sprintm-lang', newLanguage);
        }
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
};

export function Text({ tid }: any) {
    const languageContext = useContext(LanguageContext);

    return languageContext.dictionary[tid] || tid;
};

export function GetLanguage() {
    const language = window.localStorage.getItem('sprintm-lang');

    return language || 'ru';
};