import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { languageOptions } from '../languages';
import { LanguageContext, Text } from './Language'


const NavMenu = (props: any) => {
        // @ts-ignore
        const { userLanguage, userLanguageChange } = useContext(LanguageContext);

        const handleLanguageChange = () => {
            if (userLanguage == "ru")
                userLanguageChange("en")
            else
                userLanguageChange("ru")
        }

        const [header, setHeader] = useState(true);
        useEffect(() => {
            handleRoute();
        }, [])

        const handleRoute = () => {
            if (document.documentElement.clientWidth < 1080) {
                setHeader(false);
            }
        }


        return (
            <>
                <div id="header_container" className="container_header white relative sticky">
                    <div id="header" className="header">
                        <Link to="/" className={userLanguage == 'ru' ? 'header-logo_a' : 'header-logo-en_a'}>
                        </Link>
                        {header &&
                            <div id="header_menu" className="header_menu">
                            <ul className="first_lvl_menu">
                                    <li><Link to="/" className="link" onClick={handleRoute}><Text tid='nav_menu_main' /></Link></li>
                                    <li><Link to="/about" className="link" onClick={handleRoute}><Text tid='nav_menu_about' /></Link></li>
                                <li>
                                        <Link to="/services" className="link" onClick={handleRoute}><Text tid='nav_menu_services' /></Link>
                                </li>
                                    <li><Link to="/contacts" className="link" onClick={handleRoute}><Text tid='nav_menu_contacts' /></Link></li>
                                    <li><Link to="/shop" className="link" onClick={handleRoute}><Text tid='nav_menu_shop' /></Link></li>
                                    <li><Link to="/vacancy" className="link" onClick={handleRoute}><Text tid='nav_menu_vacancy' /></Link></li>
                                </ul>
                                
                            <a className="header-btn" data-toggle="modal" style={{ padding: '6px 17px' }}
                                    data-target="#exampleModal" href="#exampleModal"><Text tid='header_btn' /></a>
                                <button className="select-lang" onClick={handleLanguageChange}>{userLanguage}</button>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ height: '80px' }}></div>
                <div id="drop_menu_wrapper">
                    <div className="main-content">
                        <a data-toggle=".sidebar" id="sidebar-toggle" onClick={() => { setHeader(!header) }}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </a>
                    </div>
                </div>
                </>
        );
}

export default NavMenu
