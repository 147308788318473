import * as React from 'react';
import { Link } from 'react-router-dom';
import { Text } from './../Language'

const NotFound = () => {



    return (
        <React.Fragment>
            <div className="notfound-box">
                <h2>404</h2>
                <h4><Text tid='notfound_h4' /></h4>
                <p><Text tid='notfound_p' /></p>
                <Link to="/" className="link"><Text tid='notfound_link' /></Link>
            </div>
        </React.Fragment>
    );
}

export default NotFound