﻿import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MaskedInput, Notification, SelectPicker, useToaster } from 'rsuite';
import { LanguageContext, Text } from './Language'

const Form = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();
    const toaster = useToaster();

    const { userLanguage } = useContext(LanguageContext);

    const options = [
        {
            name: 'Россия',
            nameEn: 'Russia',
            mask: ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+7 (___) ___-__-__'
        },
        {
            name: 'Казахстан',
            nameEn: 'Kazakhstan',
            mask: ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+7 (___) ___-__-__'
        },
        {
            name: 'Беларусь',
            nameEn: 'Belarus',
            mask: ['+', '3', '7', '5', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+375 (___) ___-__-__'
        },
        {
            name: 'Азербайджан',
            nameEn: 'Azerbaijan',
            mask: ['+', '9', '9', '4', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+994 (___) ___-__-__'
        },
        {
            name: 'Армения',
            nameEn: 'Armenia',
            mask: ['+', '3', '7', '4', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+374 (___) ___-__-__'
        },
        {
            name: 'Кыргызстан',
            nameEn: 'Kyrgyzstan',
            mask: ['+', '9', '9', '6', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+996 (___) ___-__-__'
        },
        {
            name: 'Таджикистан',
            nameEn: 'Tadjikistan',
            mask: ['+', '9', '9', '2', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+992 (___) ___-__-__'
        },
        {
            name: 'Узбекистан',
            nameEn: 'Uzbekistan',
            mask: ['+', '9', '9', '8', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+998 (___) ___-__-__'
        },
        {
            name: 'Великобритания',
            nameEn: 'United Kingdom',
            mask: ['+', '4', '4', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+44 (___) ___-__-__'
        },
        {
            name: 'Вьетнам',
            nameEn: 'Vietnam',
            mask: ['+', '8', '4', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+84 (___) ___-__-__'
        },
        {
            name: 'Турция',
            nameEn: 'Turkey',
            mask: ['+', '9', '0', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+90 (___) ___-__-__'
        },
        {
            name: 'Египет',
            nameEn: 'Egypt',
            mask: ['+', '2', '0', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+20 (___) ___-__-__'
        },
        {
            name: 'Объединенные Арабские Эмираты',
            nameEn: 'United Arab Emirates',
            mask: ['+', '9', '7', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+971 (___) ___-__-__'
        }
    ]

    const [option, setOption] = useState(options[0]);
    const [phone, setPhone] = useState('');
    const [defaultOption, setDefaultOption] = useState(userLanguage == "ru" ? "Россия" : "Russia");

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            return false;
        }

        const token = await executeRecaptcha('submit');
        return token;
    };

    const message = (
        <Notification type='error' header='Ошибка' closable className="error-message">
            <p>Заполните данные формы корректно!</p>
        </Notification>
    );

    const onSubmit = (e: any) => {
        e.preventDefault();

        const email = {
            Name: e.target[0].value,
            SenderEmail: e.target[1].value,
            Country: e.target[2].value,
            Phone: e.target[3].value,
            Message: e.target[4].value,
            Subject: e.target[5].value
        }

        if (!email.SenderEmail || !email.Phone || email.Phone.includes('_')) {
            toaster.push(message);
        }
        else if (email.Name.trim() == '' || email.Message.trim() == '') {
            toaster.push(message);
        }
        else if (e.target[6].value != '') {
            // @ts-ignore
            document.querySelector('#close_button').click();
        }
        else {
            handleReCaptchaVerify().then((res) => {
                fetch(`/api/email`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Name: email.Name,
                        SenderEmail: email.SenderEmail,
                        Phone: email.Phone,
                        Country: email.Country,
                        Message: email.Message,
                        Captcha: res,
                        Subject: email.Subject
                    })
                })
                    .then(() => {

                    })
                    .catch(err => console.log(err));
            })
                .catch(err => console.log(err));

            // @ts-ignore
            document.querySelector('#close_button').click();
            e.target[0].value = ''
            e.target[1].value = ''
            e.target[3].value = ''
            e.target[4].value = ''
        }
    };

    useEffect(() => {
        setOption(options[0]);
        userLanguage == "ru" ? setDefaultOption(options[0].name) : setDefaultOption(options[0].nameEn);
    }, [userLanguage])

        return (
            <React.Fragment>
                <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close_button">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="column" id="main">
                                    <h3><Text tid='form_header' /></h3>
                                    <form method="post" onSubmit={onSubmit}>
                                        <div className="form-group">
                                            <label style={{ fontSize: '18px' }} htmlFor="exampleInputName"><Text tid='form_FIO' /></label>
                                            <input type="text" className="form-control" id="inputName" name="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <label style={{ fontSize: '18px' }} htmlFor="exampleInputEmail1"><Text tid='form_email' /></label>
                                            <input type="email" className="form-control" id="inputEmail" aria-describedby="email"
                                                name="email" required/>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label style={{ fontSize: '18px' }} htmlFor="exampleInputEmail1"><Text tid='form_phone' /></label>
                                                <SelectPicker
                                                    defaultValue={defaultOption}
                                                    cleanable={false}
                                                    searchable={false}
                                                    data={options}
                                                    valueKey={userLanguage == "ru" ? "name" : "nameEn"}
                                                    labelKey={userLanguage == "ru" ? "name" : "nameEn"}
                                                    label=''
                                                    caretAs=''
                                                    loading={false}
                                                    onSelect={(_v, item) => {
                                                        // @ts-ignore
                                                        setOption(item);
                                                        setPhone('');
                                                    }}
                                                    style={{ width: 150 }}
                                                    className='select-phone'
                                                />
                                            </div>
                                            <MaskedInput
                                                value={phone}
                                                mask={option.mask}
                                                guide={true}
                                                showMask={false}
                                                keepCharPositions={false}
                                                placeholder={option.placeholder}
                                                placeholderChar='_'
                                                onChange={setPhone}
                                                className='form-control'
                                            />

                                        </div>
                                        <div className="form-group">
                                            <label style={{ fontSize: '18px' }} htmlFor="exampleInputPassword1"><Text tid='form_message' /></label>
                                            <textarea style={{ height: '100px' }} className="form-control" id="inputMessage"
                                                name="message" maxLength={500} required></textarea>
                                        </div>
                                        <input type="hidden" name="subject" id="subject" defaultValue="Обсуждение проекта" />
                                        <input type="hidden" name="captcha" id="captchaInput" defaultValue="" />
                                        <input type="text" name="sale" id="sale" defaultValue=""/>
                                        <div className="form-group" style={{ marginBottom: 0 }}>
                                            <label style={{ fontSize: '12px' }} htmlFor="exampleInputPassword1"><Text tid='form_personal' /></label>
                                        </div>
                                        <button type="submit" style={{ marginTop: '7px' }} className="btn btn-primary"><Text tid='form_send' /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
}

export default Form;
